import React from "react"
import { inject, observer } from "mobx-react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { graphql } from "gatsby"

const PageLayout = styled.div`
  display: grid;
  font-family: Helvetica;
  width: 100%;
  height: auto;
  margin-top: 95px;
  grid-template-areas:
      "bread-crumbs"
      "content"

    grid-template-columns: 1fr;
    grid-gap: 0px;

  .alert-enter {
    opacity: 0;
    transform: scale(0.9);
  }
  .alert-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
`

const BreadcrumbsContainer = styled.div`
  grid-area: bread-crumbs;
  a {
    color: #0084ff;
  }
`

const ContentContainer = styled.div`
  grid-area: content;
  max-width: 800px;
  width: 100%;
  margin-top: 30px;
  position: relative;

  .resp-container {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
  }

  .resp-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`


const BlogTemplatePage = ({ data }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <PageLayout>
        <ContentContainer>
          <h1>
             {frontmatter.title}
          </h1>
          <div>
            Date: {frontmatter.date}
          </div>
          <br/>
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </ContentContainer>
      </PageLayout>
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
export default inject("cart")(observer(BlogTemplatePage))
